import React, {useContext} from 'react'
import { getImages } from '../../Componment/const'
import { Field, Form, Formik } from 'formik';
import ValidationError from '../../Componment/Errors/ValidationError';
import { VehicleContext } from '../../Componment/Context/VehicleContext';

const Contact = () => {
    const {submitContactForm} = useContext(VehicleContext);

    return (
        <>
            <section className='contact-main' style={{
                backgroundImage: `#161616 url(${getImages('hero-bg.webp')}) no-repeat center top`,
                backgroundSize: '100%'
            }}>
                <div className='contact-banner'>
                    <div className='container'>
                        <div className='main-heading mb-3'>Contact Us</div>
                        <p className='md-title fw-400'>Drop us a line, we'd love to hear from you</p>
                        <div className='row contact-flex'>
                            <div className='col-md-6 order-2 wow fadeIn' data-wow-delay="0.4s">
                                <Formik
                                    // validationSchema={validationSchema}
                                    initialValues={{
                                        full_name: '',
                                        email: '',
                                        phone: '',
                                        preferable_date: '',
                                        preferable_time: '',
                                        requested_services: '',
                                    }}
                                    onSubmit={async (values, {resetForm}) => {
                                        console.log(values);
                                        const data = await submitContactForm(values);
                                        if(data.success) {
                                            alert("Thank you for your submission");
                                            resetForm();
                                        } else
                                            alert("There was a problem with your submission");
                                    }}
                                >
                                    {({ values, setFieldValue, field, form }) => (
                                        <Form className="service-from gas-contact-form" autoComplete="off" name="contact-form">
                                            <div className='form-group'>
                                                <div className='cs-label'>Full Name</div>
                                                <Field
                                                    type="text"
                                                    name="full_name"
                                                    className="form-control"
                                                    required
                                                />
                                                <ValidationError name="full_name" />
                                            </div>
                                            <div className='form-group'>
                                                <div className='cs-label mb-2'>Email</div>
                                                <Field
                                                    type="text"
                                                    name="email"
                                                    className="form-control"
                                                    required
                                                />
                                                <ValidationError name="email" />
                                            </div>
                                            <div className='form-group'>
                                                <div className='cs-label mb-2'>Phone</div>
                                                <Field
                                                    type="text"
                                                    name="phone"
                                                    className="form-control"
                                                    required
                                                />
                                                <ValidationError name="phone" />
                                            </div>
                                            <div className='form-group'>
                                                <div className='cs-label'>Comments</div>
                                                <Field
                                                    as="textarea"
                                                    name="comment"
                                                    className="form-control h-247"
                                                    component="textarea"
                                                />
                                                <ValidationError name="comment" />
                                            </div>
                                            <div className='mt-5 text-center'>
                                                <button type='submit' className='green-btn lg-btn w-100'>Send</button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                            <div className='col-md-6'>
                                <div className='sf-box mb-5 contact-info-box mt-5 wow fadeIn' data-wow-delay="0.2s">
                                    <div className='md-title text-green text-uppercase mb-3'>General Inquiries</div>
                                    <p className='text-white fw-400'>
                                    55 Washington St. Brooklyn NY 11021 <br/>
                                        <span className='text-green'>info@kentmotorclub.com</span> <br/>
                                        T:  <a className='text-white' href="tel:5165174650">(516) 517-4650</a>
                                    </p>
                                </div>
                                <div className='map-box wow fadeIn' data-wow-delay="0.4s">
                                    <iframe src="https://snazzymaps.com/embed/677209" width="100%" height="400px" style={{ border: 'none' }}></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact