import React from 'react'
import { getImages } from '../../const'

const RecentlyPurchasedVehicles = () => {
    return (
        <>
            <section className='recently-vehicles-wrap'>
                <div className='container'>
                    <div className='reliable-title text-white text-start text-uppercase mb-3'>Recently purchased vehicles</div>
                    <div className='row wow fadeInUp' data-wow-delay="0.4s">
                        <div className='col-lg-3 col-md-6 col-6 mb-3'>
                            <div className='rpv-box'>
                                <div className='rpv-img'>
                                    <img src={getImages('rpv-img1.jpg')} />
                                </div>
                                <div className='rpv-content'>
                                    <div className='d-flex align-items-center mb-3 justify-content-between'>
                                        <div className='sm-title fw-900 font-1-2em'>Billy P</div>
                                        <div className='sold-btn d-inline-block text-uppercase'>Sold</div>
                                    </div>
                                    <div className='rpv-address text-white'>New York, NY</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 mb-3'>
                            <div className='rpv-box'>
                                <div className='rpv-img'>
                                    <img src={getImages('rpv-img2.jpg')} />
                                </div>
                                <div className='rpv-content'>
                                    <div className='d-flex align-items-center mb-3 justify-content-between'>
                                        <div className='sm-title fw-900 font-1-2em'>Douglas H</div>
                                        <div className='sold-btn d-inline-block text-uppercase'>Sold</div>
                                    </div>
                                    <div className='rpv-address text-white'>Queens, NY</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 mb-3'>
                            <div className='rpv-box'>
                                <div className='rpv-img'>
                                    <img src={getImages('rpv-img3.jpg')} />
                                </div>
                                <div className='rpv-content'>
                                    <div className='d-flex align-items-center mb-3 justify-content-between'>
                                        <div className='sm-title fw-900 font-1-2em'>Angie G</div>
                                        <div className='sold-btn d-inline-block text-uppercase'>Sold</div>
                                    </div>
                                    <div className='rpv-address text-white'>Brooklyn, NY</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 mb-3'>
                            <div className='rpv-box'>
                                <div className='rpv-img'>
                                    <img src={getImages('rpv-img5.jpg')} />
                                </div>
                                <div className='rpv-content'>
                                    <div className='d-flex align-items-center mb-3 justify-content-between'>
                                        <div className='sm-title fw-900 font-1-2em'>Timothy S</div>
                                        <div className='sold-btn d-inline-block text-uppercase'>Sold</div>
                                    </div>
                                    <div className='rpv-address text-white'>Long Island, NY</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RecentlyPurchasedVehicles