import React from 'react'
import { getImages } from '../../const'
import OwlCarousel from "react-owl-carousel";
import ReadMore from '../Common/ReadMore';

const InventorySmItem = ({vehicle, index, numberFormatter, goBackFlip}) => {
  return (
    <>
        <div className='col-md-4 animated zoomIn'
                style={{
                    visibility: 'visible',
                    animationDelay: '0.2s',
                    animationName: 'zoomIn'
                }}
                key={index}
            >
                <div className='pro-box'>
                    <div className='pro-content'>
                        <div className='before-hover'>
                            <div className='pro-top'>
                                <img src={vehicle.images[0]} alt='srp' />
                            </div>
                            <div className='pro-bottom'>
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                    <div className="sm-title font-1-1em">{vehicle.vehicle_name}</div>
                                    <div>
                                        <button className='info-icon'>
                                            <img src={getImages('info-icon.svg')} alt='icon' />
                                        </button>
                                    </div>
                                </div>
                                <div className="xs-title font-1em mb-2">{numberFormatter(vehicle.mileage)} miles</div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="sm-title font-1-1em">{numberFormatter(vehicle.price, true)}</div>
                                    <div><a className="green-btn" href={"/vdp?vin=" + vehicle.vin}>Vehicle Details</a></div>
                                </div>
                            </div>
                        </div>
                        <div className='after-hover'>
                            <div className='pro-top'>
                                <OwlCarousel
                                    className="owl-theme srplist-slider"
                                    loop={true}
                                    margin={0}
                                    items={1}
                                    nav={true}
                                    dots={true}
                                    autoplay={true}
                                    autoplayTimeout={2000}
                                >
                                    {vehicle.images.slice(0, 3).map((v, i) => {
                                        return (
                                            <div className='item' key={i}>
                                                <img src={v} alt='srp' />
                                            </div>
                                        )
                                    })}
                                </OwlCarousel>
                            </div>
                            <div className='pro-bottom'>
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                    <div className='d-flex align-items-center w-60'>
                                        <div className='xs-title fw-400 w-30'>Stock #</div>
                                        <div className='sm-title w-70'>{vehicle.stockno}</div>
                                    </div>
                                    <div className='d-flex align-items-center w-40'>
                                        <div className='xs-title fw-400 w-60'>Interior Color</div>
                                        <div className='sm-title w-40'>{vehicle.interior_color}</div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                    <div className='d-flex align-items-center w-60'>
                                        <div className='xs-title fw-400 w-30'>VIN</div>
                                        <div className='sm-title w-70'>{vehicle.vin}</div>
                                    </div>
                                    <div className='d-flex align-items-center w-40'>
                                        <div className='xs-title fw-400 w-60'>Exterior Color</div>
                                        <div className='sm-title w-40'>{vehicle.exterior_color}</div>
                                    </div>
                                </div>
                                <hr className='custom-hr' />
                                <ReadMore
                                    key={index}
                                    text={vehicle.description}
                                    maxChars={230}
                                    sectionId="aboutVdp"
                                    readMoreUrl={"/vdp?vin=" + vehicle.vin}
                                />
                                {/* <p>We are proud to present this beautiful Porsche Taycan. Finished in Volcano Grey lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt &nbsp;<span className='text-green'>Read More</span></p> */}
                                <div className="d-flex align-items-center justify-content-between mt-4 pt-2 srp-actions-flex">
                                    <div>
                                        <button type='button' className='view-sticker-btn text-green'><span className='doc_icon'></span> View Window Sticker</button>
                                    </div>
                                    <div><a className="green-btn" href={"/vdp?vin=" + vehicle.vin} >Vehicle Details</a></div>
                                </div>
                            </div>
                            <div className='text-center d-none'>
                                <button className='flip-back-btn' type='button'
                                    onClick={goBackFlip}
                                >
                                    <img src={getImages('refresh-icon.svg')} />
                                    Flip Card
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default InventorySmItem