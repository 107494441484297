import React from 'react'
import { getImages, LazyImage } from '../../const'
import { Link } from 'react-router-dom'

const HomeServiceInside = () => {
    return (
        <>
            <div className="panel-inner panel-inside-bg d-flex align-items-center">
                <div className='w-50 order-2'>
                    <div className='mxw-470 mx-auto'>
                        
                        <div className="xxl-title text-start">Reconditioning</div>
                        <p className='text-green'>Our Ferrari Master techs are trained to fix any issue on the world’s foremost brands.</p>
                        <ul className='fvb-list text-start'>
                            <li>Ferrari factory trained technicians and staff</li>
                            <li>Only Factory OE parts</li>
                            <li>Flat-bed pickup and delivery</li>
                            <li>Unmatched Pricing. Low expense structure passes along value that cannot be matched</li>
                        </ul>
                        <div>
                            <Link to="/exotic-car-appraisal" className='green-btn xl-btn d-inline-block'>Appraise Now</Link>
                        </div>
                    </div>
                </div>
                <div className='w-50 img-100vh  d-none d-md-block'>
                    <LazyImage src={getImages('service-inside-img.webp')} alt="" />
                </div>
            </div>
        </>
    )
}

export default HomeServiceInside