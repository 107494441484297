import React from 'react'
import { getImages, LazyBackground } from '../../const'
import CommonForm from '../Common/CommonForm'

const JoinMallingList = () => {
    return (
        <>
            <LazyBackground src={getImages('become-member.webp')} className="lazy-bg drop-line-wrap join-mailing-wrap">
                {/* <section className='drop-line-wrap join-mailing-wrap'
                style={{
                    backgroundImage: `url(${getImages('become-member.webp')}) no-repeat center center`,
                    backgroundSize: 'cover'
                }}
            > */}
                <div className='container mxw-980' id="join-mailing-form">
                    <div className='main-heading mb-0 text-uppercase text-center wow fadeInDown' data-wow-delay="0.2s">DROP US A LINE</div>
                    <p className='text-center font-1-3em text-white wow fadeInDown' data-wow-delay="0.4s">We’d love to hear from you, there are no bad questions!</p>
                    <div className='mt-2 mb-4 text-center md-title'>
                        <a href='tel:5165174650' className='text-white'><img src={getImages('local-phone-material.svg')} /> &nbsp;&nbsp;<span className='text-green'>Call Us</span> &nbsp;&nbsp;<span>(516) 517-4650</span></a>
    
                    </div>
                    <CommonForm />
                </div>
                {/* </section> */}
            </LazyBackground>
        </>
    )
}

export default JoinMallingList