import React from 'react'
import { getImages, LazyBackground } from '../../const'
import { Link } from 'react-router-dom'

const HomeService = () => {
    return (
        <>
            <LazyBackground id="serviceDiv" src={getImages('service-full-bg.webp')} className="lazy-bg panel-inner add-overlay-bg">
                {/* <div id="serviceDiv" className="panel-inner add-overlay-bg" style={{ background: `#000 url(${getImages('service-full-bg.webp')}) no-repeat center center` }}> */}
                <div className='pi-pos'>
                    <div className='container'>
                        <div className='xxl-title'>Reconditioning</div>
                        <div className='mxw-990 xl-title my-4 mx-auto'>Our Ferrari Master techs are trained to fix any issue on the world’s foremost brands.</div>
                        <div className='mt-5 text-center'>
                            <Link to="/exotic-car-appraisal" type='button' className='green-btn xl-btn d-inline-block'>Appraise Now</Link>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </LazyBackground>
        </>
    )
}

export default HomeService